@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-inputs/styles/material.css";
/*GOOGLE FONTS*/
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
/* import the TreeView dependency styles */

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";

:root {
  --black: #343A40;
  --dark-grey: #6C757D;
  --medium-grey: #E0E0E0;
  --light-grey: #EFEFEF;
  --white: #ffffff;
  --red: #F45971;
  --green: #43DAA8;

}

:focus-visible {
  outline: none !important;
}

* body {
  font-family: 'Lato' !important;
  min-height: 100vh;
  color: var(--black) !important;
}

* .e-control, .e-css {
  font-family: 'Lato' !important;
}

.bg-white {
  background-color: var(--white);
}


.c-black {
  color: var(--black);
}

.c-dark-grey {
  color: var(--dark-grey);
}

.c-medium-grey {
  color: var(--medium-grey);
}

.c-red {
  color: var(--red);
}

.c-green {
  color: var(--green);
}

.font-weight-bold {
  font-weight: 800;
}

.font-weight-semibold {
  font-weight: 600;
}

.font-weight-regular {
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .font-weight-sm-bold {
    font-weight: 800;
  }

  .font-weight-sm-semibold {
    font-weight: 600;
  }

  .font-weight-sm-regular {
    font-weight: 400;
  }
}

.font-weight-sm-bold, .font-weight-semibold, .font-weight-regular {
  line-height: 1.3;
}

.font-size-56 {
  font-size: 56px;
}

.font-size-46 {
  font-size: 46px;
}

.font-size-38 {
  font-size: 38px;
}

.font-size-36 {
  font-size: 36px;
}

.font-size-32 {
  font-size: 32px;
}

.font-size-28 {
  font-size: 28px;
}

.font-size-25 {
  font-size: 25px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-21 {
  font-size: 21px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-19 {
  font-size: 19px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-17 {
  font-size: 17px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-11 {
  font-size: 11px;
}

@media screen and (max-width: 768px) {
  .font-size-sm-56 {
    font-size: 56px;
  }

  .font-size-sm-46 {
    font-size: 46px;
  }

  .font-size-sm-38 {
    font-size: 38px;
  }

  .font-size-sm-36 {
    font-size: 36px;
  }

  .font-size-sm-32 {
    font-size: 32px;
  }

  .font-size-sm-28 {
    font-size: 28px;
  }

  .font-size-sm-25 {
    font-size: 25px;
  }

  .font-size-sm-24 {
    font-size: 24px;
  }

  .font-size-sm-22 {
    font-size: 21px;
  }

  .font-size-sm-21 {
    font-size: 21px;
  }

  .font-size-sm-20 {
    font-size: 20px;
  }

  .font-size-sm-19 {
    font-size: 19px;
  }

  .font-size-sm-18 {
    font-size: 18px;
  }

  .font-size-sm-17 {
    font-size: 17px;
  }

  .font-size-sm-16 {
    font-size: 16px;
  }

  .font-size-sm-15 {
    font-size: 15px;
  }

  .font-size-sm-14 {
    font-size: 14px;
  }

  .font-size-sm-13 {
    font-size: 13px;
  }

  .font-size-sm-12 {
    font-size: 12px;
  }

  .font-size-sm-11 {
    font-size: 11px;
  }
}