.e-select {
    height: 38px;
    border-radius: 8px;
    border-color: var(--medium-grey);
    width: 370px;
    max-width: 100%;
}

  

#dropdown-level {
     height: 38px; 
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--medium-grey);
    border-radius: 8px;
}
.formDropDownTree {
    width: 370px;
    max-width: 100%;
}

.e-treeview .e-ul{
    padding: 0 0 0 6px;
}


.e-treeview .e-list-item .e-ul {
    padding: 0 0 0 12px;
}