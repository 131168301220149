.my-input {
    height: 38px;
    border-radius: 8px;
    border: 1px solid var(--medium-grey);
    width: 370px;
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.my-input:focus-visible {
    border-width: 2px;
}

.labelValidation.valid {
    color: var(--green)
}

.labelValidation.invalid {
    color: var(--red)
}

.my-input.valid {
    border-color: var(--green)
}

.my-input.invalid {
    border-color: var(--red)
}

.btn-primary.green {
    border-color: var(--green);
    background-color: var(--green)
}

.btn-primary.red {
    border-color: var(--red);
    background-color: var(--red)
}

button.formMail {
    max-width: 100%;
    width: 300px;
}




.btn-check:active+.btn-primary.green, .btn-check:checked+.btn-primary.green, .btn-primary.green.active, .btn-primary.green:active, .show>.btn-primary.green.dropdown-toggle {
    color: #fff;
    background-color: var(--green);
    border-color: var(--green);
}
.btn-check:focus+.btn-primary.green, .btn-primary.green:focus{
    color: #fff;
    background-color: var(--green);
    border-color: var(--green);
}
.btn-primary.green:hover {
    color: #fff;
    background-color: var(--green);
    border-color: var(--green);
}

.btn-check:active+.btn-primary.red, .btn-check:checked+.btn-primary.red, .btn-primary.red.active, .btn-primary.red:active, .show>.btn-primary.red.dropdown-toggle {
    color: #fff;
    background-color: var(--red);
    border-color: var(--red);
}


.btn-check:focus+.btn-primary.red, .btn-primary.red:focus{
    color: #fff;
    background-color: var(--red);
    border-color: var(--red);
}
.btn-primary.red:hover {
    color: #fff;
    background-color: var(--red);
    border-color: var(--red);
}


.btn-primary.green.disabled, .btn-primary.green:disabled{

    color: #fff;
    background-color: var(--green);
    border-color: var(--green);
}

.btn-primary.red.disabled, .btn-primary.red:disabled{

    color: #fff;
    background-color: var(--red);
    border-color: var(--red);
}

